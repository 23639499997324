<template>
  <b-card
    v-if="statisticsItems"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Cantidades Disponibles</b-card-title>
      <b-spinner
        v-show="loading"
        variant="info"
        label="Loading..."
        class="float-right"
        />
      <b-button
        v-show="!loading"
        variant="info"
        class="float-right"
        type="submit"
        @click="downloadAllProducts()"
      >
        Descargar productos
      </b-button>
    </b-card-header>
    <b-card-body class="text-center">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.product_type_id"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto">
              <h3 class="font-weight-bolder mb-0">
                {{ formatNumber(item.number_products) }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                {{ item.product_type_name }}
              </b-card-text>
              <b-card-text class="font-small-3 mb-0">
                <span class="text-primary">Alistados / Pistoleados:</span> {{ item.early_enlistment }}
              </b-card-text>
              <b-card-text class="font-small-3 mb-0">
                <span class="text-danger">Con novedad:</span> {{ item.qty_products_newness }}
              </b-card-text>

              <!-- Action Buttons -->
              <b-spinner
                v-show="loading"
                variant="primary"
                label="Loading..."
                class="mb-1 mb-sm-0 mr-0 mt-2"
                />

              <b-button
                v-show="!loading"
                v-if="item.number_products > 0"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mt-2"
                type="submit"
                @click="downloadProducts(item.product_type_id, item.product_type_name)"
              >
                Descargar {{ item.product_type_name }}
              </b-button>

            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          md="12"
          class="text-center mt-1">

          <hr>

          <b-row>
            <b-col
              md="4"
              >
              <b-card-text class=" mb-0">
                <span class="text-info">SIMs:</span> {{ countSims }}
                <b-button
                  v-if="datSims"
                  variant="outline-primary"
                  class="ml-2"
                  @click="dataSimsModal = true"
                  size="sm"
                >
                  Ver más
                </b-button>
                <b-spinner
                  v-show="loading"
                  variant="info"
                  label="Loading..."
                  class="mt-1 ml-2"
                />
                <b-button
                  v-show="!loading"
                  variant="info"
                  class="ml-2"
                  @click="downloadSims()"
                  size="sm"
                >
                  Descargar sims
                </b-button>
              </b-card-text>
            </b-col>

            <!-- <b-col
              md="4">
              <b-card-text class=" mb-0">
                <span class="text-info">Fundas:</span> {{ cases }}
              </b-card-text>
            </b-col> -->

            <b-col
              md="4">
              <b-card-text class=" mb-0">
                <span class="text-info">Kits:</span> {{ kits }}
              </b-card-text>
            </b-col>
          </b-row>
          
        </b-col>
      </b-row>
    </b-card-body>

    <b-modal
      v-model="dataSimsModal"
      title="Sims"
      ok-only
      ok-title="Entendido"
    >
      <div class="table-container">
        <table class="custom-table">
          <thead>
            <tr>
              <th>Operador</th>
              <th>Tamaño</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in datSims" :key="index">
              <td>{{ item.operator }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.total }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      
    </b-modal>

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BButton, BSpinner, BFormSelect, BFormGroup, BModal,
} from 'bootstrap-vue'
import axios from 'axios'
import moment from 'moment';
import {constants} from "@/helpers/constants"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    BSpinner,
    BFormSelect,
    BFormGroup,
    BModal,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      statisticsItems: [],
      loading: false,
      orderNumber: null,
      countSims: null,
      cases: null,
      kits: null,
      datSims: null,
      dataSimsModal: false,
    }
  },
  created() {
    this.getStats()
  },
  methods: {
    getStats() {

      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-general-stats', { headers }).then(response => {
        console.log('get-general-stats: ', response)

        this.statisticsItems = response.data.data.count_products
        this.countSims = response.data.data.count_sims
        this.datSims = response.data.data.data_sims
        this.cases = response.data.data.cases
        this.kits = response.data.data.kits
        
      }).catch( err => {
        console.log(err)
      })

    },
    formatNumber(value) {
      var formatter = new Intl.NumberFormat('es-ES', {
        style: 'decimal',
        minimumFractionDigits: 0
      });
      return formatter.format(parseInt(value, 10));
    },
    downloadProducts(product_type_id, product_name) {

      this.loading = true
      const vm = this

      let xhr = new XMLHttpRequest();
      xhr.open('post', this.host + '/api/front-web/download-products', true);
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader("Authorization", 'Bearer ' + this.accessToken);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function () {
        vm.loading = false
        if (this.status === 200) {
          let date = moment().format('DD-MM-YYYY HH:mm:ss');

          let blob = new Blob([this.response], {type: this.getResponseHeader('content-type')});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = product_name + ' - ' + date + '.xlsx';
          link.click();
          
        } else {
          if (callback != null) {
            callback()
          }
        }
      };
      let params = 'product_type_id=' + product_type_id;
      xhr.send(params)
      xhr.th

    },
    downloadSims() {

      this.loading = true
      const vm = this

      let xhr = new XMLHttpRequest();
      xhr.open('post', this.host + '/api/front-web/download-sims', true);
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader("Authorization", 'Bearer ' + this.accessToken);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function () {
        vm.loading = false
        if (this.status === 200) {
          let date = moment().format('DD-MM-YYYY HH:mm:ss');

          let blob = new Blob([this.response], {type: this.getResponseHeader('content-type')});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'sims-GLP-ZF-' + date + '.xlsx';
          link.click();
          
        } else {
          if (callback != null) {
            callback()
          }
        }
      };
      xhr.send()
      xhr.th

    },
    downloadAllProducts() {

      this.loading = true
      const vm = this

      let xhr = new XMLHttpRequest();
      xhr.open('post', this.host + '/api/front-web/download-all-products', true);
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader("Authorization", 'Bearer ' + this.accessToken);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function () {
        vm.loading = false
        if (this.status === 200) {
          let date = moment().format('DD-MM-YYYY HH:mm:ss');

          let blob = new Blob([this.response], {type: this.getResponseHeader('content-type')});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'inventario productos - ' + date + '.xlsx';
          link.click();
          
        } else {
          if (callback != null) {
            callback()
          }
        }
      };
      xhr.send()
      xhr.th

    },

  },
}
</script>


<style>
  .table-container {
    width: 100%;
    overflow-x: auto;
  }

  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }

  .custom-table th, .custom-table td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
  }
</style>
